// 移动端首页
<template>
  <div class="m_total" id="mtotal">
    <!-- 导航 -->
    <div class="mlogin_nav">
      <img src="../../assets/img/left2.png" @click="YS.routeBack()" alt="logo图标" />
      <a>登录优视频</a>
    </div>
    <div class="mlogin_block"></div>
    <!-- <div class="m_login_b"></div> -->
    <div class="m_login_c" @click="YS.routetogo({ path: '/mhome' })"></div>
    <div class="mlogin_dialog-body">
      <!-- tag页头 -->
      <div class="mlogin_dialog" id="loginIpu">
        <div class="mlogin_dialog-tag">
          <!-- 双标签头 -->
          <div
            class="login-tag"
            v-show="$store.state.userStatus != 'forget' && $store.state.userStatus != 'userLogin'"
          >
            <!-- 用户名注册 -->
            <div
              id="ltl"
              class="lt-l login-tag-act"
              @click="$store.commit('changeStatus', logincode[$store.state.userStatus].tagL)"
            >
              <span id="ltlspan">{{ logincode[$store.state.userStatus].tagL }}</span>
            </div>
            <!-- 展位线 -->
            <div class="lt-c"></div>
            <!-- 邮箱注册 -->
            <div
              id="ltr"
              class="lt-r"
              @click="$store.commit('changeStatus', logincode[$store.state.userStatus].tagR)"
            >
              <span id="ltrspan">{{ logincode[$store.state.userStatus].tagR }}</span>
            </div>
          </div>
          <!-- 单标签头 -->
          <div
            class="for-tag"
            v-show="$store.state.userStatus == 'forget' || $store.state.userStatus == 'userLogin'"
          >
            <div id="ltc" class="lt-cc">
              <span id="ltcspan">{{ logincode[$store.state.userStatus].tagC }}</span>
            </div>
          </div>
        </div>
        <div class="mlogin_dialog-input">
          <input
            class="lt-input"
            v-model="registered.username"
            :placeholder="logincode[$store.state.userStatus].inpT"
          />
          <input
            class="lt-input"
            v-model="registered.password"
            :placeholder="logincode[$store.state.userStatus].inpB"
            type="password"
          />
          <div id="vercodeIpu" class="vercode-input">
            <input
              class="lt-input"
              maxlength="6"
              v-model="registered.captcha"
              placeholder="图形验证码"
              @keyup.enter="
                $store.commit('changeStatus', logincode[$store.state.userStatus].butR);
                TOsign();
              "
            />
            <a id="vercodea" class="vercode" @click="TOvercode">
              <img :src="verimg.url" alt="登录验证码" />
            </a>
          </div>
          <div id="codeIpu" class="code-input">
            <input
              v-model="captcha"
              class="lt-input mcodeinput"
              maxlength="6"
              placeholder="请输入验证码"
            />
            <a id="codea" class="varcode" @click="getvarcode">
              {{ $store.state.code.count }}
            </a>
          </div>
        </div>
      </div>
      <div class="mlogin_dialog-button">
        <div
          class="ltf-l"
          @click="
            $store.commit('changeStatus', logincode[$store.state.userStatus].butL);
            TOvercode();
          "
        >
          <span>{{ logincode[$store.state.userStatus].butL }}</span>
        </div>
        <div
          class="ltf-r"
          :class="{ cliloading: loadCli }"
          @click="
            $store.commit('changeStatus', logincode[$store.state.userStatus].butR);
            TOsign();
          "
        >
          <span v-show="!loadCli">{{ logincode[$store.state.userStatus].butR }}</span>
          <div class="dots-6" v-show="loadCli"></div>
        </div>
      </div>
      <!-- 忘记密码？ -->
      <div class="lt-g">
        <a
          class="forgetmess"
          href="javascript:void(0)"
          @click="$store.commit('changeStatus', logincode[$store.state.userStatus].bot)"
        >
          {{ logincode[$store.state.userStatus].bot }}
        </a>
      </div>
      <!-- 用户协议 -->
      <div
        class="lt-protocol"
        v-show="$store.state.userStatus == 'userRes' || $store.state.userStatus == 'mailRes'"
      >
        <input type="checkbox" name="vehicle" v-model="protocol" />
        <span>我已阅读并同意</span>
        <a @click="protocolfun">《优视频用户协议》</a>
      </div>
      <!-- 输入框input -->
    </div>
    <!-- WIN 失败弹窗 -->
    <transition name="mcenter_8">
      <div class="mpayresult2" v-show="haveplo">
        <!-- <div class="mpayresult2" v-show="true"> -->
        <div class="payrbody3">
          <a class="mvip_fail">{{ failmess }}</a>
          <img class="marb" src="../../assets/img/mvip_fail.png" alt="失败提示弹窗提醒图标" />
        </div>
        <div class="payafter">
          <span @click="openandclo('haveplo', false)">返回重试</span>
        </div>

        <img
          class="login-close"
          @click="openandclo('haveplo', false)"
          src="../../assets/img/close.png"
          alt="关闭失败提示弹窗"
        />
      </div>
    </transition>
    <!-- WIN 公用弹窗成功 -->
    <transition name="mcenter_8">
      <div class="mpayresult1" v-show="haveok">
        <!-- <div class="mpayresult1" v-show="true"> -->
        <div class="payrbody11">
          <img src="../../assets/img/pass_ok.png" alt="成功弹窗的主要图片" />
          <span>{{ okmess }}</span>
        </div>
        <div class="payrbody12">
          <span @click="openandclo('haveok', false)">确认</span>
        </div>
        <img
          class="login-close"
          @click="openandclo('haveok', false)"
          src="../../assets/img/close.png"
          alt="关闭成功弹窗弹窗"
        />
      </div>
    </transition>
    <!-- WIN 用户协议弹窗 -->
    <transition name="mcenter_8">
      <div class="mpayresult2" v-show="xieyi">
        <!-- <div class="mpayresult2" v-show="true"> -->
        <div class="payrbody3">
          <span class="ml_title">{{ getpage.title }}</span>
          <pre class="ml_pre"><span v-html="getpage.content" class="xieyispan"></span>
          </pre>
        </div>

        <img
          class="login-close"
          @click="openandclo('xieyi', false)"
          src="../../assets/img/close.png"
          alt="关闭用户协议弹窗"
        />
      </div>
    </transition>
    <Mbottom v-show="false"></Mbottom>
    <!-- 遮罩层 -->
    <div id="coverm"></div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mbottom from '../../components/moblie/mBottom';
export default {
  //inject: ['routerRefresh'], //在子组件中注入在父组件中创建的属性
  components: {
    Mbottom,
  },
  name: 'mLogin',
  data() {
    return {
      haveok: false, //成功弹窗
      okmess: '', //成功弹窗文本信息
      loadCli: false,
      xieyi: false, //用户协议
      haveplo: false,
      getpage: {}, //通过标识取页面内容
      failmess: '', //失败弹窗文本信息
      logincode: {
        userLogin: {
          title: '登录优视频',
          tagL: '用户名登录',
          tagR: '邮箱登录',
          tagC: '用户名/邮箱登录',
          inpT: '请输入用户名/邮箱',
          inpB: '请输入密码',
          butL: '注册账号',
          butR: '立即登录',
          bot: '忘记密码？',
        }, //用户登录
        mailLogin: {
          title: '登录优视频',
          tagL: '用户名登录',
          tagR: '邮箱登录',
          tagC: '用户名/邮箱登录',
          inpT: '请输入邮箱地址',
          inpB: '请输入密码',
          butL: '注册账号',
          butR: '立即登录',
          bot: '忘记密码？',
        }, //邮箱登录
        userRes: {
          title: '注册优视频',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: false,
          inpT: '请输入用户名',
          inpB: '请输入密码',
          butL: '返回登录',
          butR: '立即注册',
          bot: '忘记密码？',
        }, //用户注册
        mailRes: {
          title: '注册优视频',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: false,
          inpT: '请输入邮箱',
          inpB: '请输入密码',
          butL: '返回登录',
          butR: '立即注册',
          bot: '忘记密码？',
        }, //邮箱注册
        forget: {
          title: '忘记密码',
          tagL: '用户名注册',
          tagR: '邮箱注册',
          tagC: '邮箱重置密码',
          inpT: '请输入邮箱',
          inpB: '请输入新密码',
          butL: '返回登录',
          butR: '立即重置',
          bot: '账号注册',
        }, //忘记密码
      },
      protocol: true, //用户协议
      //图像验证码
      verimg: {
        url: '',
        id: '',
      },
      registered: {
        username: '',
        password: '',
        captcha: '',
        phrase_id: '',
      },
      phrase_id: '', //邮箱验证码
      captcha: '', //邮箱验证码
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      window.location.href = window.g.WEB_URL;
    }
  },
  mounted() {
    that.$store.commit('changeStatus', '用户名登录');
    that.TOvercode();
  },
  methods: {
    //开关窗口
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (boo) {
        coverm.style.display = 'block';
      } else {
        coverm.style.display = 'none';
      }
    },
    //获取邮箱验证码
    getvarcode() {
      that.$store.state.code.count === '验证码' &&
        that.api.user
          .api_emailCode({ email: that.registered.username })
          .then((data) => {
            that.YS.getCode();
            that.phrase_id = data.phrase_id;
          })
          .catch((error) => {
            that.failmess = error.message;
            that.openandclo('haveplo', true);
          });
    },
    //注册/登录
    TOsign() {
      if (that.loadCli) return false;
      that.loadCli = true;
      //普通注册
      if (vuex.userStatus == 'userRes') {
        that.api.user
          .api_registered(that.registered)
          .then((data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
            that.$store.commit('changelogin');
            that.YS.routetogo({ path: '/mcenter' });
            //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
            that.$store.commit('useloginBefore');
          })
          .catch((error) => {
            that.failmess = error.message;
            that.openandclo('haveplo', true);
            that.TOvercode();
          })
          .finally(() => {
            that.loadCli = false;
          });
      }
      //邮箱注册
      else if (vuex.userStatus == 'mailRes') {
        fetch
          .api_passportsignupByEmail({
            email: that.registered.username,
            password: that.registered.password,
            captcha: that.captcha,
            phrase_id: that.phrase_id,
          })
          .then((data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
            that.$store.commit('changelogin');
            that.YS.routetogo({ path: '/mcenter' });
            //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
            that.$store.commit('useloginBefore');
          })
          .catch((error) => {
            that.failmess = error.message;
            that.openandclo('haveplo', true);
            that.getvarcode();
          })
          .finally(() => {
            that.loadCli = false;
          });
      }
      //忘记密码
      else if (vuex.userStatus == 'forget') {
        fetch
          .api_resetPasswordByEmail({
            email: that.registered.username,
            new_password: that.registered.password,
            captcha: that.captcha,
            phrase_id: that.phrase_id,
          })
          .then((data) => {
            that.okmess = '重置密码成功';
            that.openandclo('haveok', true);
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
            that.$store.commit('changelogin');
            that.YS.routetogo({ path: '/mcenter' });
            //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
            that.$store.commit('useloginBefore');
          })
          .catch((error) => {
            that.failmess = error.message;
            that.openandclo('haveplo', true);
          })
          .finally(() => {
            that.loadCli = false;
          });
      }
      //登录
      else if (vuex.userStatus == 'userLogin') {
        fetch
          .api_login(that.registered)
          .then((data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
            // that.YS.closeallWin(); //关闭所有弹窗
            // that.YS.gotoVip({}, 'close', 'loginReg'); //关闭登录弹窗
            // fetch.api_info({}).then((data) => {
            //   vuex.user = data.user;
            //   console.log('api_info' + data);
            // });
            if (localStorage.getItem('mloginAfter')) {
              var localNow = JSON.parse(localStorage.getItem('mloginAfter'));
              localStorage.removeItem('mloginAfter');
              that.YS.routetogo({ path: localNow.path, query: localNow.query });
            } else {
              that.YS.routetogo({ path: '/mcenter' });
            }
            //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
            that.$store.commit('changelogin');
            that.$store.commit('useloginBefore');
          })
          .catch((error) => {
            that.failmess = error.message;
            that.openandclo('haveplo', true);
            that.TOvercode();
          })
          .finally(() => {
            that.loadCli = false;
          });
      }
      // that.loadCli = false;
    },
    //获取验证码
    TOvercode() {
      fetch.api_verCode({}).then((data) => {
        that.verimg.url = 'data:image/jpeg;base64,' + data.captcha_data;
        that.registered.phrase_id = data.phrase_id;
      });
    },
    //点击协议
    protocolfun() {
      that.getpageapi('service-terms', 'xieyi');
      that.openandclo('xieyi', true);
    },
    //api通过标识取页面内容
    // 服务条款          service-terms
    // 充值协议          recharge-agrrement
    // 关于我们          about-us
    // 联系我们          contact-us
    // 加入我们          join-us
    // 广告投放          advertising
    async getpageapi(type, win) {
      try {
        let data = await fetch.api_page({ symbol: type });
        if (data && data.page.rich == 1) {
          //富文本
          that.getpage = data.page;
          // that.getpage.content = data.page.content;
          that.YS.gotoVip(1, 'open', win);
        } else if (data && data.page.rich == 0) {
          //纯文字

          that.getpage = data.page;
          that.getpage.content = data.page.content.replace(/。/g, '。<br>');
          that.YS.gotoVip(1, 'open', win);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scope></style>
